import { toRefs, ref, reactive } from 'vue'
import { isEmpty } from 'lodash-es'
import axios from '@tenant/core/init/axios'
import BaseAxios from 'axios'
import qs from 'qs'
/**
 *
 * @param {string} endpoint
 * @param {'GET'|'POST'|'PUT'|'PATCH'|'DELETE'|'OPTIONS'|'HEAD'} method
 */
export const useApi = (endpoint, method) => {
    const result = ref(null)
    const rawResult = ref(null)
    const endpointRef = ref(endpoint)

    const state = reactive({
        errorMessage: null,
        errors: null,
        hasErrors: false,
        code: null,
        parameters: null,
        status: null,
        loading: false,
        processing: false,
        success: false,
    })

    const replaceEndpoint = (newEndpoint) => {
        endpointRef.value = newEndpoint
    }

    const setLoading = (loading) => {
        state.loading = loading
    }

    const replaceEndpointByParams = (endpointUrl, params) => {
        if (!params) {
            return endpointUrl
        }

        for (const [param, value] of Object.entries(params)) {
            endpointUrl = endpointUrl.replaceAll(`{${param}}`, value)
        }

        return endpointUrl
    }

    let cancelTokenSource = BaseAxios.CancelToken.source()

    /**
     *
     * @param {object} data
     * @param {object} queries
     * @param {object} params
     * @param {onBeforeUnmount} queryParams
     * @param {object} headers
     * @param {object} configs
     * @returns {Promise<any>}
     */
    const execute = async ({
        data = null,
        queries = null,
        params = null,
        queryParams = null,
        headers = {},
        ...configs
    } = {}) => {
        state.errorMessage = null
        state.errors = null
        state.hasErrors = false
        state.code = null
        state.parameters = null
        state.status = null
        state.loading = true
        state.processing = true
        state.success = false

        let endpointUrl = endpointRef.value
        endpointUrl = replaceEndpointByParams(endpointUrl, params)

        if (queryParams) {
            endpointUrl = `${endpointUrl}?${qs.stringify(queryParams)}`
        }

        try {
            const response = await axios({
                method,
                data,
                headers,
                params: queries,
                url: endpointUrl,
                cancelToken: cancelTokenSource.token,
                ...configs,
            })

            rawResult.value = response
            result.value = response.data
            state.success = true

            return response.data
        } catch (e) {
            state.processing = false
            if (e.response) {
                const { data, status } = e.response

                state.errors = data.data || []
                state.code = data.code
                state.parameters = data.parameters
                state.hasErrors = !isEmpty(data.data)
                state.status = status
                state.errorMessage = data.message

                return Promise.reject(state)
            } else {
                state.errorMessage = e.message
            }

            result.value = null

            throw e
        } finally {
            state.loading = false
        }
    }

    const recreateCancelToken = () => {
        cancelTokenSource.cancel()
        cancelTokenSource = BaseAxios.CancelToken.source()
    }

    return {
        rawResult,
        result,
        execute,
        cancelTokenSource,
        recreateCancelToken,
        replaceEndpoint,
        setLoading,
        ...toRefs(state),
    }
}
